<script>
import ProcessForm from './ProcessForm'

export default {
  extends: ProcessForm,

  data() {
    return {
      dangerButtonText: 'Удалить процесс'
    }
  },

  created() {
    this.getProcess(this.$route.params.id).then(response => {
      this.form = {
        ...response,
        booking_date: response.booking_date || this.form.booking_date
      }

      this.startRoundTime = response.round_time[0].slice(0, -3)
      this.endRoundTime = response.round_time[1].slice(0, -3)

      if (response.schedule_type === 'WEEK') {
        this.week_days = response.schedule_days
      } else {
        this.month_days = response.schedule_days
      }

      this.setInitFormData(this.form)
    })
  },

  methods: {
    handleFormSubmit() {
      const result = this.beforeSubmit()

      if (!result) {
        return
      }

      if (this.$v.$invalid) {
        this.$refs.form.validate()
        window.snackbar('Ошибки в форме', 'error')
        return
      }

      this.isSubmit = true

      this.updateProcess(this.form.id, this.getFilteredFormData())
        .then(() => {
          window.snackbar('Процесс успешно добавлен', 'info')
          this.cancelForm({ routeName: 'processes' })
        })
        .finally(() => { this.afterSubmit() })
    },

    handleDeleteProcess() {
      this.isSubmit = true

      this.deleteProcess(this.$route.params.id)
        .then(() => {
          window.snackbar( 'Процесс успешно удален', 'info')
          this.cancelForm({ routeName: 'processes' })
        })
        .finally(() => { this.isSubmit = false })
    }
  }
}
</script>
